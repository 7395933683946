const breakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 950,
        lg: 1536,
        xl: 1920

        // xs: 0,
        // sm: 600,
        // md: 900,
        // lg: 1200,
        // xl: 1536,
    }
};

export default breakpoints;
