const breadcrumb = [
    {
        id: '01',
        breadItem: 'Courses',
        link: '/dashboard/courses/my-courses'
    },
    {
        id: '02',
        breadItem: 'Create new course'
    }
];

export default breadcrumb;
